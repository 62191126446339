import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet de color molt variable que va de blanc grisenc a terrós groguenc amb el centre del capell més fosc. Aquest de 4 a 7 cm de diàmetre, primer és convex per passar prest a aplanat, carnós, lluent amb el marge sinuós, freqüentment aixecat als exemplars de més edat. Davall el capell les làmines són nombroses, lliures i blanquinoses. El peu és més ample a la part inferior, un poc fibrós, flexible i llanós cap a la base. És freqüent trobar el peu un poc aplanat. Les espores són blanques en massa, molt nombroses, hialines, el·líptiques, de 6-9 x 3-4,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      